<template>
  <div class="w-full">
    <table class="w-full">
      <thead>
        <tr>
          <th class="rounded-t tracking-wide text-3xl md:text-5xl p-4 font-display font-bold text-white bg-golf-green" colspan="5">
            LEADERBOARD
          </th>
        </tr>
        <tr class="text-white">
          <th rowspan="2" class="border border-gray-600 border-t-0 text-white bg-news-black text-left pl-4">Team</th>
          <th colspan="3" class="border border-gray-600 border-t-0 text-white bg-news-black">Day</th>
          <th rowspan="2" class="border border-gray-600 border-t-0 text-white bg-news-black cursor-pointer hover:underline" @click="sortCol = 'overall'">Pts.</th>
        </tr>
        <tr class="text-white">
          <th class="border border-gray-600 text-white bg-news-black cursor-pointer hover:underline" @click="sortCol = 'day1'">1</th>
          <th class="border border-gray-600 text-white bg-news-black cursor-pointer hover:underline" @click="sortCol = 'day2'">2</th>
          <th class="border border-gray-600 text-white bg-news-black cursor-pointer hover:underline" @click="sortCol = 'day3'">3</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="team in leaders" :key="team.name" class="text-news-black">
          <td class="bg-white p-2 pl-4 border text-left">{{ team.name }}</td>
          <td class="bg-white p-2 border">{{ team.scores.day1?team.scores.day1:'-' }}</td>
          <td class="bg-white p-2 border">{{ team.scores.day2?team.scores.day2:'-' }}</td>
          <td class="bg-white p-2 border">{{ team.scores.day3?team.scores.day3:'-' }}</td>
          <td class="bg-white p-2 border">{{ team.scores.overall?team.scores.overall:'-' }}</td>
        </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="5" class="rounded-b text-gray-400 p-4 text-sm bg-news-black text-center">Results Updated Each Night</td>
      </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: "Leaderboard",
  props: {
    teams: {
      type: Array,
      required: true
    }
  },
  data: function(){
    return {
      sortCol: "overall"
    }
  },
  computed: {
    leaders: function(){
      return [...this.teams].map((team)=>{
        team.scores.overall = team.scores.day1 + team.scores.day2 + team.scores.day3;
        return team;
      }).sort((a,b) => {
        if(this.sortCol === "overall"){
          if(b.scores.overall === a.scores.overall){
            if(a.name < b.name){
              return -1
            }else if(a.name > b.name){
              return 1
            }else{
              return 0
            }
          }
          return (b.scores.overall - a.scores.overall);
        }else{
          return (b.scores[this.sortCol] - a.scores[this.sortCol]);
        }

      })
    }
  }
}
</script>

<style scoped>

  table tbody tr:first-child {
    font-weight: bold;
    font-size: 1.25rem;
  }


</style>