<template>
  <div class="home mx-auto max-w-lg text-center">
    <div class="text-news-black my-6">
      <h2 class="text-5xl font-serif">Penfield</h2>
      <p class="inline-block text-xl font-serif tracking-wider py-y border-t border-b border-gray-400">COUNTRY CLUB</p>
    </div>
    <img class="inline-block max-w-sm" alt="Vue logo" src="../assets/shankertational-hawk.jpg">
    <div class="font-serif text-news-black my-4">
      <p class="text-2xl">2024</p>
      <h1 class="text-2xl md:text-3xl tracking-wide">SHANKERTATIONAL</h1>
    </div>
    <leaderboard :teams="leaderboard" class="mb-8"></leaderboard>

    <div class="my-8 text-sm px-4">
      <p class="mb-4 text-sm px-4">Scoring is based on total match points each day.</p>
      <p>For more info, contact the site administrator. If you don't know who the site admin is, you don't need to contact him.</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Leaderboard from "@/components/Leaderboard";

export default {
  name: 'Home',
  components: {
    Leaderboard,
  },
  mounted: async function(){
    this.loadTournament().then((tournament) => {
      this.tournament = tournament;
    }).catch((e) => {
      console.log(e);
    })

  },
  data: function() {
    return  {
      tournamentId: "403685542622920770",
      tournament: undefined,
      teams: [
        {name: "Short / Chantra", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Ball / Zilnik", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Edwards / Edwards", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Shearer / Kelly", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Stoler / Emmick", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Giancursio / Delvecchio", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Grover / Staerr", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Greenwood / Brophy", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Drews / Puccia", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }},
        {name: "Wagner / Wagner", scores: {day1: 0, day2: 0, day3: 0, overall: 0 }}
      ]
    }
  },
  methods: {

    loadTournament: function(){
      let token = "fnAFWZwIy5AAQOZUdGFPPk69cVZsgOED3ZvO8hkz";
      var client = new this.$db.Client({ secret: token })
      var q = this.$db.query;

      return client.query(
          q.Get(q.Ref(q.Collection("tournaments"), this.tournamentId))
      ).then((response) => {

        if(response.data){
          let record = response.data;
          record.id = response.ref.value.id;
          return record;
        }else{
          throw new Error("Document error.");
        }
      })
    }
  },
  computed: {

    teamsList: function() {
      if (this.tournament && this.tournament.teams) {
        return Object.keys(this.tournament.teams).map((team) => {
          let teamInfo = this.tournament.teams[team];
          return teamInfo
        })
      }

      return []

    },


    leaderboard: function(){

        let points = {
          day1: {},
          day2: {},
          day3: {}
        };


        Object.keys(points).forEach((day) => {
          let currentPoints = this.teamsList.length;
          let dayLeaders = [...this.teamsList].sort((a,b) => {
            return (a.scores[day] - b.scores[day]);
          })
          dayLeaders.forEach((team,index) => {
            if(index){
              if(team.scores[day].points !== dayLeaders[index-1].scores[day]){
                currentPoints = this.teamsList.length - index;
              }
            }

            if(team.scores[day]){
              points[day][team.id] = currentPoints;
            }
          })
        });

        console.log(points);


        let leaders = this.teamsList.map((teamInfo) => {
          teamInfo.scores.overall = (points.day1[teamInfo.id]?points.day1[teamInfo.id]:0) + (points.day2[teamInfo.id]?points.day2[teamInfo.id]:0) + (points.day3[teamInfo.id]?points.day3[teamInfo.id]:0);
          return teamInfo
        })



        return leaders;



    },






  }

}
</script>

<style scoped>
  .home {

  }
</style>
